// extracted by mini-css-extract-plugin
export var body = "styles-module--body--xVy6E";
export var container = "styles-module--container--uHJEo";
export var content = "styles-module--content--uHyYv";
export var cta = "styles-module--cta--bFmyZ";
export var fadeIn = "styles-module--fadeIn--WhGYV";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--b7fEc";
export var headline = "styles-module--headline--mMEua";
export var image = "styles-module--image--Q6VLf";
export var scaleUp = "styles-module--scaleUp--nJ4Bj";
export var slideIn = "styles-module--slideIn--nu3Hm";
export var twoColumnLayout = "styles-module--twoColumnLayout--sJO+Q";