import React, { useEffect, createRef } from "react";
import useAnalytics from "../../effects/useAnalytics";
import cx from "classname";
import { whitepaperLink } from "./styles.module.scss";

export default function WhitepaperLink({ link, className }) {
  const linkRef = createRef();
  const analytics = useAnalytics();
  const name = link.label;

  useEffect(() => {
    if (analytics.trackLink && linkRef.current) {
      analytics.trackLink(linkRef.current, "Downloaded Paper", { name });
    }
  }, [analytics, name, linkRef]);

  return (
    <a
      href={link.url}
      ref={linkRef}
      className={cx(whitepaperLink, className)}
      target="_blank"
      rel="noreferrer"
    >
      {name}
    </a>
  );
}
