import React from "react";
import {
  container,
  image,
  headline,
  body,
  header,
  statHeadline,
  progress,
  progressContainer,
  stats,
  statLabel,
  statValue,
  stat,
  dropBg,
  divider
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import people from "./people.png";
import upArrow from "./up-arr.svg";
import downArrow from "./down-arr.svg";
import drop from "./drop.svg";

export default function Participants({ data }) {
  return (
    <>
      <div className={container}>
        <SiteGutter>
          <SiteGrid>
            <div className={header}>
              <img src={people} alt="" className={image} />
              <div>
                <Headline className={headline} type="s32f">
                  {data.headline}
                </Headline>
                <div className={divider} />
                <Body richText={data.body} className={body} />
              </div>
            </div>

            <Headline className={statHeadline} type="s32f">
              {data.stat}
            </Headline>

            <div className={progressContainer}>
              <div className={progress} />
            </div>

            <div className={stats}>
              <Stat
                arrowDirection="down"
                label={data.stress_label}
                value={data.stress_value}
              />
              <Stat
                arrowDirection="up"
                label={data.sleep_label}
                value={data.sleep_value}
              />
              <Stat
                arrowDirection="up"
                label={data.focus_label}
                value={data.focus_value}
              />
            </div>
          </SiteGrid>
        </SiteGutter>
      </div>
      <div className={dropBg}>
        <img src={drop} alt="" />
      </div>
    </>
  );
}

function Stat({ arrowDirection, label, value }) {
  return (
    <div className={stat}>
      <span className={statLabel}>{label}</span>
      {arrowDirection === "up" ? (
        <img src={upArrow} alt="up arrow" />
      ) : (
        <img src={downArrow} alt="down arrow" />
      )}
      <span className={statValue}>{value}</span>
    </div>
  );
}
