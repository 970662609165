import React from "react";
import cx from "classname";
import {
  container,
  contentContainer,
  studyInfoContainer,
  headline,
  body,
  link,
  orbContainer,
  mobileOrb,
  desktopOrb,
  image
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import Link from "../../components/Link";

import desktopOrbMask from "./assets/desktopOrbMask.svg";
import mobileOrbMask from "./assets/mobileOrbMask.svg";

export default function RealWorldBenefits({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <div className={contentContainer}>
            <div className={orbContainer}>
              <img
                loading="lazy"
                alt={data.image.image.description}
                src={data.image.image.file.url}
                width={`${data.image.image.file.details.image.width}px`}
                height={`${data.image.image.file.details.image.height}px`}
                className={image}
              />
              <img
                src={mobileOrbMask}
                alt=""
                className={cx(mobileOrb, "mobileOnly")}
              />
              <img
                src={desktopOrbMask}
                alt=""
                className={cx(desktopOrb, "desktopOnly")}
              />
            </div>
            <div className={studyInfoContainer}>
              <Headline className={headline} type="s32f">
                {data.headline}
              </Headline>
              <Body className={body} richText={data.body} />
              <Link url={data.cta.url} className={cx(link, "linkUnderline")}>
                {data.cta.label}
              </Link>
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
