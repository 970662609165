// extracted by mini-css-extract-plugin
export var body = "styles-module--body--LwAs4";
export var container = "styles-module--container--OBjbU";
export var contentContainer = "styles-module--contentContainer--zZMae";
export var coveImg = "styles-module--coveImg---L1nz";
export var fadeIn = "styles-module--fadeIn--Y6RUH";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--CbbT7";
export var headline = "styles-module--headline--4v6S1";
export var imgContainer = "styles-module--imgContainer--e81HN";
export var leftOrb = "styles-module--leftOrb--Mjibc";
export var link = "styles-module--link--o+Fgo";
export var researchLinks = "styles-module--researchLinks--r9CJM";
export var rightOrb = "styles-module--rightOrb--P8RA1";
export var rightOrbMobile = "styles-module--rightOrbMobile--JY5FA";
export var scaleUp = "styles-module--scaleUp--bMFyq";
export var slideIn = "styles-module--slideIn--hkVoE";
export var studyInfoContainer = "styles-module--studyInfoContainer--3ZUz9";