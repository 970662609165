// extracted by mini-css-extract-plugin
export var body = "styles-module--body--XPdeV";
export var container = "styles-module--container--2IzKk";
export var contentContainer = "styles-module--contentContainer--O028X";
export var desktopOrb = "styles-module--desktopOrb--yuKNh";
export var fadeIn = "styles-module--fadeIn--6G7fy";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--KBvKu";
export var headline = "styles-module--headline--JOZrw";
export var image = "styles-module--image--7jowT";
export var link = "styles-module--link--ygoLt";
export var mobileOrb = "styles-module--mobileOrb--3U1SQ";
export var orb = "styles-module--orb--jKip2";
export var orbContainer = "styles-module--orbContainer--nLC1K";
export var scaleUp = "styles-module--scaleUp--3miVd";
export var slideIn = "styles-module--slideIn--GBkoz";
export var studyInfoContainer = "styles-module--studyInfoContainer--O1qFd";