import React from "react";
import { container, content, headline, gradient } from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";

export default function ScienceOfTouch({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <div className={content}>
            <Headline type="s32f" className={headline}>
              {data.headline}
            </Headline>
            <Body richText={data.body} />
          </div>
        </SiteGrid>
      </SiteGutter>
      <div className={gradient} />
    </div>
  );
}
