import React from "react";
import BottomTextStat from "../BottomTextStat";
import StatsTemplate from "../StatsTemplate";
import animationData from "./icon-sleep.json";
import WhitepaperLink from "../../../components/WhitepaperLink";

export default function Sleep({ data }) {
  return (
    <StatsTemplate
      headline={data.headline}
      animationData={animationData}
      body={data.body}
      link={data.link ? <WhitepaperLink link={data.link} /> : null}
    >
      <BottomTextStat value={data.stat1_value} label={data.stat1_label} />
      <BottomTextStat value={data.stat2_value} label={data.stat2_label} />
      <BottomTextStat value={data.stat3_value} label={data.stat3_label} />
      <BottomTextStat value={data.stat4_value} label={data.stat4_label} />
    </StatsTemplate>
  );
}
