import React from "react";
import { container, headline, body } from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";

export default function AffectiveTouch({ data }) {
  return (
    <SiteGutter>
      <SiteGrid>
        <div className={container}>
          <Headline type="s32f" className={headline}>
            {data.headline}
          </Headline>
          <Body className={body} richText={data.body} />
        </div>
      </SiteGrid>
    </SiteGutter>
  );
}
