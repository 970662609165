import React from "react";
import {
  imageContainer,
  container,
  content,
  headline,
  body,
  cta
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import Link from "../../components/Link";
import TwoColumnLayout from "../../components/TwoColumnLayout";
import desktopImage from "./desktop.jpg";
import mobileImage from "./mobile.jpg";

export default function PowerOfTouch({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <TwoColumnLayout left={<Image />} right={<Content data={data} />} />
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}

function Image() {
  return (
    <div className={imageContainer}>
      <img
        src={desktopImage}
        alt="Power of Touch"
        loading="lazy"
        className="desktopOnly"
      />
      <img
        src={mobileImage}
        alt="Power of Touch"
        loading="lazy"
        className="mobileOnly"
      />
    </div>
  );
}

function Content({ data }) {
  return (
    <div className={content}>
      <Headline type="s32f" className={headline}>
        {data.headline}
      </Headline>
      <Body className={body} richText={data.body} />
      <Link className={cta} url={data.cta.url}>
        {data.cta.label}
      </Link>
    </div>
  );
}
