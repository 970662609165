import React from "react";
import {
  container,
  headline,
  body,
  cta,
  content,
  drop,
  animationContainer,
  body2
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import Link from "../../components/Link";
import TwoColumnLayout from "../../components/TwoColumnLayout";
import dropImage from "./drop.svg";
import BodyLottie2 from "./BodyLottie2";

export default function Insula({ data }) {
  return (
    <>
      <div className={container}>
        <SiteGutter>
          <SiteGrid>
            <TwoColumnLayout
              left={<Content data={data} />}
              right={<Animation data={data} />}
            />
          </SiteGrid>
        </SiteGutter>
      </div>
      <img src={dropImage} alt="" className={drop} />
    </>
  );
}

function Content({ data }) {
  return (
    <div className={content}>
      <Headline type="s32f" className={headline}>
        {data.headline}
      </Headline>
      <Body className={body} richText={data.body} />
    </div>
  );
}

function Animation({ data }) {
  return (
    <div className={animationContainer}>
      <BodyLottie2 />
      <Body className={body2} richText={data.body2} />
      <Link className={cta} url={data.cta.url}>
        {data.cta.label}
      </Link>
    </div>
  );
}
