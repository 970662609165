import React, { useEffect } from "react";
import useAnalytics, { pageView } from "../effects/useAnalytics";
import BasePage from "../components/BasePage";
import * as styles from "./styles.module.scss";
import WaypointTag from "../components/WaypointTag";
import ScienceOfTouch from "./ScienceOfTouch";
import PowerOfTouch from "./PowerOfTouch";
import Insula from "./Insula";
import AffectiveTouch from "./AffectiveTouch";
import SingleSession from "./SingleSession";
import Relaxation from "./Relaxation";
import ValidatedBy from "./ValidatedBy";
import Participants from "./Participants";
import ScienceStatistics from "./ScienceStatistics";
import PeerReviewed from "./PeerReviewed";
import RealWorldBenefits from "./RealWorldBenefits";
import xrayDesktop from "./xray-desktop.jpg";
import xrayMobile from "./xray-mobile.jpg";
import SiteGrid from "../components/SiteGrid";
import SiteGutter from "../components/SiteGutter";

export default function Science({ pageContext, location }) {
  const analytics = useAnalytics();

  useEffect(() => {
    pageView("Science Viewed");
    analytics.track("Science Viewed", {
      referrer: document.referrer
    });
  }, [analytics]);

  return (
    <>
      <BasePage pageContext={pageContext} location={location}>
        <h1 className="srOnly">{pageContext.slices.ScienceOfTouch.headline}</h1>
        <div>
          <WaypointTag value="ScienceOfTouch" />
          <ScienceOfTouch data={pageContext.slices.ScienceOfTouch} />
          <WaypointTag value="PowerOfTouch" />
          <PowerOfTouch data={pageContext.slices.PowerOfTouch} />
          <WaypointTag value="Insula" />
          <Insula data={pageContext.slices.Insula} />
          <WaypointTag value="AffectiveTouch" />
          <AffectiveTouch data={pageContext.slices.AffectiveTouch} />
          <WaypointTag value="SingleSession" />
          <SingleSession data={pageContext.slices.SingleSession} />

          <div className={styles.xray}>
            <SiteGutter>
              <SiteGrid>
                <img src={xrayDesktop} alt="x-ray" className="desktopOnly" />
                <img src={xrayMobile} alt="x-ray" className="mobileOnly" />
              </SiteGrid>
            </SiteGutter>
          </div>

          <WaypointTag value="Relaxation" />
          <Relaxation data={pageContext.slices.Relaxation} />
          <WaypointTag value="ValidatedBy" />
          <ValidatedBy data={pageContext.slices.ValidatedBy} />
          <WaypointTag value="Participants" />
          <Participants data={pageContext.slices.Participants} />
          <WaypointTag value="ScienceStatistics" />
          <ScienceStatistics data={pageContext.slices.ScienceStatistics} />
          <WaypointTag value="PeerReviewed" />
          <PeerReviewed data={pageContext.slices.PeerReviewed} />
          <WaypointTag value="RealWorldBenefits" />
          <RealWorldBenefits data={pageContext.slices.RealWorldBenefits} />
          <WaypointTag value="ScientificAdvisoryBoard" />
        </div>
      </BasePage>
    </>
  );
}
