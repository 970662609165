import React from "react";
import {
  container,
  headline,
  body,
  studyContainer,
  content,
  cta
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import Link from "../../components/Link";
import TwoColumnLayout from "../../components/TwoColumnLayout";
import mri from "./mri.jpg";

function Content({ data }) {
  return (
    <div className={content}>
      <Headline type="s32f" className={headline}>
        {data.headline}
      </Headline>
      <Body className={body} richText={data.body} />
    </div>
  );
}

function Study({ data }) {
  return (
    <div className={studyContainer}>
      <Link url={data.cta.url}>
        <img src={mri} alt="" />
        <div className={`button blue fluid ${cta}`}>{data.cta.label}</div>
      </Link>
    </div>
  );
}

export default function SingleSession({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <TwoColumnLayout
            left={<Content data={data} />}
            right={<Study data={data} />}
          />
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
