import React from "react";
import cx from "classname";
import {
  container,
  contentContainer,
  leftOrb,
  rightOrb,
  studyInfoContainer,
  headline,
  body,
  link,
  researchLinks,
  coveImg,
  imgContainer,
  rightOrbMobile
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import Link from "../../components/Link";

import topLeftOrb from "./assets/topLeftOrb.svg";
import topRightOrb from "./assets/topRightOrb.svg";
import mobileOrb from "./assets/mobileOrb.svg";
import coveAtHome from "./assets/coveAtHome.png";
import mobileCoveAtHome from "./assets/mobileCoveAtHome.png";

export default function PeerReviewed({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <div className={contentContainer}>
            <div className={studyInfoContainer}>
              <Headline className={headline} type="s32f">
                {data.headline}
              </Headline>
              <Body className={body} richText={data.body} />
              <div className={researchLinks}>
                <Body>{data.researchSubhead}</Body>
                {Object.values(data.ScienceResearchLinks).map((_link, i) => (
                  <Link
                    url={_link.url}
                    className={cx(link, "linkUnderline")}
                    key={i}
                  >
                    {_link.label}
                  </Link>
                ))}
              </div>
            </div>
            <div className={imgContainer}>
              <img
                src={coveAtHome}
                className={cx(coveImg, "desktopOnly")}
                alt=""
              />
              <img
                src={mobileCoveAtHome}
                className={cx(coveImg, "mobileOnly")}
                alt=""
              />
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
      <img src={topLeftOrb} className={cx(leftOrb, "desktopOnly")} alt="" />
      <img src={topRightOrb} className={cx(rightOrb, "desktopOnly")} alt="" />
      <img
        src={mobileOrb}
        className={cx(rightOrbMobile, "mobileOnly")}
        alt=""
      />
    </div>
  );
}
