import React from "react";
import * as styles from "./styles.module.scss";
import Lottie from "lottie-react";
import SiteGutter from "../../../components/SiteGutter";
import SiteGrid from "../../../components/SiteGrid";
import Headline from "../../../components/Headline";
import Body from "../../../components/Body";
import cx from "classname";

export default function StatsTemplate({
  headline,
  body,
  animationData,
  children,
  link,
  className,
  iconClassName
}) {
  return (
    <div className={cx(styles.container, className)}>
      <SiteGutter>
        <SiteGrid className="sleep-and-stress">
          <div className={styles.header}>
            <div className={styles.iconContainer}>
              <div className={cx(styles.icon, iconClassName)}>
                <Lottie
                  loop={true}
                  autoplay={true}
                  animationData={animationData}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice"
                  }}
                  width="100%"
                />
              </div>
            </div>

            <div>
              <Headline type="s32f" className={styles.headline}>
                {headline}
              </Headline>
              <div className={styles.divider} />
              <Body richText={body} />
              {link}
            </div>
          </div>

          <div className={styles.grid}>{children}</div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
