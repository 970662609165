import React from "react";
import { container } from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Body from "../../components/Body";

export default function ValidatedBy({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <Body richText={data} />
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
