// extracted by mini-css-extract-plugin
export var container = "styles-module--container--82aQt";
export var divider = "styles-module--divider--WP1z2";
export var fadeIn = "styles-module--fadeIn--y3Y35";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--Mf4sI";
export var grid = "styles-module--grid--l7eq9";
export var header = "styles-module--header--b4mds";
export var headline = "styles-module--headline--nG46F";
export var icon = "styles-module--icon--ZHlP7";
export var iconContainer = "styles-module--iconContainer--+BUgg";
export var scaleUp = "styles-module--scaleUp--Uiixi";
export var slideIn = "styles-module--slideIn--uQiyR";