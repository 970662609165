// extracted by mini-css-extract-plugin
export var body = "styles-module--body--HlZYg";
export var container = "styles-module--container--Gm3mv";
export var content = "styles-module--content--JbxGO";
export var cta = "styles-module--cta--0FHss";
export var fadeIn = "styles-module--fadeIn--x8UXz";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--X9KWF";
export var headline = "styles-module--headline--nSZfr";
export var imageContainer = "styles-module--imageContainer--xyZoL";
export var scaleUp = "styles-module--scaleUp--6OZwr";
export var slideIn = "styles-module--slideIn--HT5d4";