import React from "react";
import {
  container,
  headline,
  body,
  content,
  cta,
  image,
  twoColumnLayout
} from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import Link from "../../components/Link";
import TwoColumnLayout from "../../components/TwoColumnLayout";
import woman from "./woman.jpg";

function Content({ data }) {
  return (
    <div className={content}>
      <Headline type="s32f" className={headline}>
        {data.headline}
      </Headline>
      <Body className={body} richText={data.body} />
      <Link url={data.cta.url} className={cta}>
        {data.cta.label}
      </Link>
    </div>
  );
}

function Image({ data }) {
  return (
    <div className={image}>
      <img src={woman} alt="Woman wearing Cove" />
    </div>
  );
}

export default function Relaxation({ data }) {
  return (
    <div className={container}>
      <SiteGutter>
        <SiteGrid>
          <TwoColumnLayout
            className={twoColumnLayout}
            left={<Image data={data} />}
            right={<Content data={data} />}
          />
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
