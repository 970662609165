import React from "react";
import { container } from "./styles.module.scss";
import Stress from "./Stress";
import Sleep from "./Sleep";
import ImprovedCognition from "./ImprovedCognition";

export default function ScienceStatistics({ data }) {
  return (
    <div className={container}>
      <section>
        <Stress data={data.Stress} />
      </section>
      <section>
        <Sleep data={data.Sleep} />
      </section>
      <section>
        <ImprovedCognition data={data.ImprovedCognition} />
      </section>
    </div>
  );
}
