// extracted by mini-css-extract-plugin
export var animationContainer = "styles-module--animationContainer--UTOWk";
export var body = "styles-module--body--f9Z2R";
export var body2 = "styles-module--body2--IJJP6";
export var container = "styles-module--container--Q3r1V";
export var content = "styles-module--content--SzEGm";
export var cta = "styles-module--cta--Q6-By";
export var drop = "styles-module--drop--e5aWU";
export var fadeIn = "styles-module--fadeIn--v+mMz";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--5XVME";
export var headline = "styles-module--headline--sm81y";
export var scaleUp = "styles-module--scaleUp--owLMQ";
export var slideIn = "styles-module--slideIn--jUeGK";