// extracted by mini-css-extract-plugin
export var body = "styles-module--body--JnRQU";
export var container = "styles-module--container--U2nwd";
export var content = "styles-module--content--cbSSn";
export var cta = "styles-module--cta--6YZVc";
export var fadeIn = "styles-module--fadeIn--KRXqF";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--O0Yki";
export var headline = "styles-module--headline--yYqyA";
export var scaleUp = "styles-module--scaleUp--6PRDK";
export var slideIn = "styles-module--slideIn--PV+Pc";
export var studyContainer = "styles-module--studyContainer--ak8sv";