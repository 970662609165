import React from "react";
import { container } from "./styles.module.scss";
import cx from "classname";

export default function TwoColumnLayout({
  className,
  leftWidth,
  rightWidth,
  left,
  right
}) {
  return (
    <div className={cx(container, className)}>
      <div style={{ width: leftWidth }}>{left}</div>
      <div style={{ width: rightWidth }}>{right}</div>
    </div>
  );
}
