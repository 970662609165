import React from "react";
import StatsTemplate from "../StatsTemplate";
import animationData from "./icon-lightbulb.json";
import WhitepaperLink from "../../../components/WhitepaperLink";
import * as styles from "./styles.module.scss";
import BottomTextStat from "../BottomTextStat";

export default function ImprovedCognition({ data }) {
  return (
    <StatsTemplate
      className={styles.statsContainer}
      headline={data.headline}
      animationData={animationData}
      body={data.body}
      link={data.cta ? <WhitepaperLink link={data.cta} /> : null}
      iconClassName={styles.icon}
    >
      <BottomTextStat value={data.stat1_value} label={data.stat1_label} />
      <BottomTextStat value={data.stat2_value} label={data.stat2_label} />
      <BottomTextStat value={data.stat3_value} label={data.stat3_label} />
      <BottomTextStat value={data.stat4_value} label={data.stat4_label} />
    </StatsTemplate>
  );
}
