// extracted by mini-css-extract-plugin
export var body = "styles-module--body--jzHdp";
export var container = "styles-module--container--XP7f5";
export var divider = "styles-module--divider--ACVdU";
export var dropBg = "styles-module--dropBg--xbUeB";
export var fadeIn = "styles-module--fadeIn--TlS0Y";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--Nd63z";
export var header = "styles-module--header--zrfgc";
export var headline = "styles-module--headline--elm2H";
export var image = "styles-module--image--uPFL-";
export var progress = "styles-module--progress--zkQXW";
export var progressContainer = "styles-module--progressContainer--7xfpG";
export var scaleUp = "styles-module--scaleUp--g4Qnc";
export var slideIn = "styles-module--slideIn--tTzpA";
export var stat = "styles-module--stat--lgUuY";
export var statHeadline = "styles-module--statHeadline--GSgmQ";
export var statLabel = "styles-module--statLabel--1qrBs";
export var statValue = "styles-module--statValue--rMxzP";
export var stats = "styles-module--stats--F9+Ue";