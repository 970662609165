import React from "react";
import * as styles from "./styles.module.scss";
import cx from "classname";
import Body from "../../../components/Body";

export default function BottomTextStat({ value, label, className }) {
  return (
    <div className={cx(styles.bottomTextStat, className)}>
      <div className={styles.value}>{value}</div>
      <Body>{label}</Body>
    </div>
  );
}
